<template>
    <article class="w-full h-full bg-yellow-350 relative base-responsive-rounding">
        <div>
            <div @click="close">
                <Button
                    btn-close
                    class="absolute z-2"
                    :class="isOn3dMap ? 'top-2 right-2 lg:top-4 lg:right-4' : 'top-4 right-4'"
                />
            </div>

            <div :class="isOn3dMap && 'flex flex-row lg:flex-col'">
                {{/* Thumbnail */}}
                <div v-if="img">
                    <a
                        v-if="url"
                        :href="url"
                        target="_blank"
                        class="base-responsive-rounding aspect-h-3 aspect-w-5 w-full h-full"
                        :class="isOn3dMap && 'min-w-[7rem] lg:min-w-0'"
                    >
                        <img v-if="img" :src="img" :alt="title" :class="isOn3dMap && 'object-cover'" />
                    </a>

                    <div v-else class="base-responsive-rounding aspect-h-3 aspect-w-5 w-full h-full">
                        <img v-if="img" :src="img" :alt="title" />
                    </div>
                </div>

                {{/* Content */}}
                <div class="pt-4 px-4 pb-4 lg:pb-7" :class="recommendedProgram ? 'lg:px-7' : 'lg:px-5'">
                    {{/* Title */}}
                    <a
                        v-if="url"
                        :href="url"
                        target="_blank"
                        class="map-place-card-title"
                        :class="isOn3dMap ? 'text-base leading-[1.4] mr-6 lg:mr-0' : 'text-xl'"
                    >
                        {{ title }}
                    </a>

                    <div
                        v-else
                        class="map-place-card-title"
                        :class="isOn3dMap ? 'text-base leading-[1.4] mr-6 lg:mr-0' : 'text-xl'"
                    >
                        {{ title }}
                    </div>

                    {{/* Description (optional) */}}
                    <div
                        v-if="description"
                        class="max-h-40 overflow-auto mt-2"
                        :class="isOn3dMap && 'text-sm leading-[1.4]'"
                        v-html="description"
                    ></div>

                    {{/* Read more button, if place has an url (optional) */}}
                    <div v-if="url" class="mt-3">
                        <Button
                            v-if="isOn3dMap"
                            :label="ctaLabel || $t('generic.read_more')"
                            :to="url"
                            target="_blank"
                            class="p-button-secondary p-button-text -ml-2"
                            icon="pi pi-chevron-right"
                            icon-pos="right"
                        />
                        <div v-else class="text-center">
                            <Button :label="ctaLabel || $t('generic.read_more')" :to="url" target="_blank" />
                        </div>
                    </div>

                    {{/* Recommended program (optional) */}}
                    <div v-if="recommendedProgram" class="mt-4">
                        <div class="bg-black" style="height: 1px"></div>

                        <RecommendedEvent
                            :url="$getEventUrl(recommendedProgram)"
                            :title="recommendedProgram.name"
                            :image="$getMediaImage(recommendedProgram.media, 'lead')"
                            :date="$getFormattedEventInterval(recommendedProgram)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import RecommendedEvent from '@/components/Cards/MapPlaceCard/RecommendedEvent.vue';

export default {
    name: 'MapPlaceCard',
    components: {
        RecommendedEvent,
    },
    props: {
        url: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: false,
            default: null,
        },
        ctaLabel: {
            type: String,
            required: false,
            default: null,
        },
        recommendedProgram: {
            type: Object,
            required: false,
            default: null,
        },
        close: {
            type: Function,
            required: true,
        },
        isOn3dMap: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.map-place-card-title {
    @apply relative font-Poppins lg:text-2xl font-semibold;
}
</style>
